<template>
  <div id="rounds-bg" style="min-height: 100vh">
    <div id="rounds-content">
      <div class="rounds-header">
        <div>
          <h1 class="rounds-title">{{ $t("rounds_title") }}</h1>
          <h2 class="rounds-sub-title">{{ $t("rounds_sub_title") }}</h2>
        </div>
        <div>
          <NewDxaButton
            data-test="Round:Rounds:BtnCreateNewRound"
            @btnFunction="createNewRound"
            :disabled="isManagerUser"
            :title="$t('link_create_new_round')"
          />
        </div>
      </div>
      <!-- rounds section of filter -->
      <div class="rounds-filter">
        <h3 class="rounds-filters-title">
          {{ $t("filters") }}
        </h3>
        <v-form
          @submit.stop.prevent="getRoundsByPage"
          class="rounds-filters-items-flex"
        >
          <div>
            <span class="rounds-filters-label">{{
              $t("rounds_company_title")
            }}</span>
            <v-autocomplete
              :items="roundsAllCompanies"
              item-text="Name"
              item-value="CompanyId"
              v-model="roundsSelectedCompany"
              color="primary"
              dense
              class="rounds-filters-auto-complete"
              :label="$t('select_label')"
              outlined
              data-test="Round:Rounds:InputFilterCompany"
            >
            </v-autocomplete>
          </div>
          <div>
            <span class="rounds-filters-label">{{
              $t("rounds_state_title")
            }}</span>
            <v-autocomplete
              data-test="Round:Rounds:InputFilterStatus"
              :items="roundStatusItems"
              :item-text="translateRoundStatus"
              item-value="value"
              v-model="roundsSelectedStatus"
              color="primary"
              dense
              class="rounds-filters-auto-complete"
              :label="$t('select_label')"
              outlined
            >
            </v-autocomplete>
          </div>
          <div>
            <span class="rounds-filters-label">{{
              $t("rounds_time_title")
            }}</span>
            <div class="rounds-date-flex-items">
              <v-menu
                ref="startMenuDateRef"
                v-model="roundStartMenuDate"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    color="#AFAFAF"
                    background-color="#E9E9E9"
                    dense
                    class="rounds-filters-date"
                    outlined
                    style="width: 100%"
                    v-model="roundCaptureStartDateText"
                    v-mask="['##/##/####']"
                    append-icon="mdi-calendar"
                    @blur="
                      roundCaptureStartDate = formatDateOnBlur(
                        roundCaptureStartDateText
                      )
                    "
                    v-bind="attrs"
                    v-on="on"
                    label="DD/MM/AA"
                    height="34"
                  ></v-text-field>
                </template>
                <v-date-picker
                  locale="pt-BR"
                  color="primary"
                  v-model="roundCaptureStartDate"
                  min="1850-01-01"
                  @change="saveRoundCaptureStartDate"
                ></v-date-picker>
              </v-menu>
              <span class="rounds-date-item-space-letter">a</span>
              <v-menu
                ref="endMenuDateRef"
                v-model="roundEndMenuDate"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    color="#AFAFAF"
                    background-color="#E9E9E9"
                    dense
                    class="rounds-filters-date"
                    outlined
                    style="width: 100%"
                    v-model="roundCaptureFinishDateText"
                    v-mask="['##/##/####']"
                    append-icon="mdi-calendar"
                    @blur="
                      roundCaptureFinishDate = formatDateOnBlur(
                        roundCaptureFinishDateText
                      )
                    "
                    v-bind="attrs"
                    v-on="on"
                    label="DD/MM/AA"
                    height="34"
                  ></v-text-field>
                </template>
                <v-date-picker
                  locale="pt-BR"
                  color="primary"
                  v-model="roundCaptureFinishDate"
                  min="1850-01-01"
                  @change="saveRoundCaptureEndDate"
                ></v-date-picker>
              </v-menu>
            </div>
          </div>
          <div class="rounds-filters-item-button-to-filter-box">
            <NewDxaButton
              data-test="Round:Rounds:ButtonFilterRounds"
              @btnFunction="getRoundsByPage"
              :loading="loading"
              type="submit"
              :outline="true"
              :title="$tc('filter_kpi', 1)"
            />
          </div>
        </v-form>
      </div>
      <!-- rounds table -->
      <div v-if="loading" class="box-loading">
        <v-progress-circular
          indeterminate
          size="35"
          color="primary"
        ></v-progress-circular>
      </div>
      <div v-else>
        <v-simple-table>
          <thead class="rounds-table-header rounds-table-head">
            <th>
              {{ $t("single_round") }}
              <v-icon
                small
                color="primary"
                @click="headerClick(orderFilterEnum.ROUND)"
                >{{
                  orderByMode === orderByModeEnum.ASC
                    ? "mdi-arrow-up"
                    : "mdi-arrow-down"
                }}</v-icon
              >
            </th>
            <th>
              {{ $t("company_single") }}
              <v-icon
                small
                color="primary"
                @click="headerClick(orderFilterEnum.COMPANY)"
                >{{
                  orderByMode === orderByModeEnum.ASC
                    ? "mdi-arrow-up"
                    : "mdi-arrow-down"
                }}</v-icon
              >
            </th>
            <th>
              {{ $t("startDate") }}
              <v-icon
                color="primary"
                small
                @click="headerClick(orderFilterEnum.STARTFUNDING)"
                >{{
                  orderByMode === orderByModeEnum.ASC
                    ? "mdi-arrow-up"
                    : "mdi-arrow-down"
                }}</v-icon
              >
            </th>
            <th>
              {{ $t("endDate") }}
              <v-icon
                small
                color="primary"
                @click="headerClick(orderFilterEnum.ENDFUNDING)"
                >{{
                  orderByMode === orderByModeEnum.ASC
                    ? "mdi-arrow-up"
                    : "mdi-arrow-down"
                }}</v-icon
              >
            </th>
            <th>
              {{ $t("status") }}
              <v-icon
                small
                color="primary"
                @click="headerClick(orderFilterEnum.STATUS)"
                >{{
                  orderByMode === orderByModeEnum.ASC
                    ? "mdi-arrow-up"
                    : "mdi-arrow-down"
                }}</v-icon
              >
            </th>
            <th>
              {{ $t("capture") }}
              <v-icon
                small
                color="primary"
                @click="headerClick(orderFilterEnum.CAPTURE)"
                >{{
                  orderByMode === orderByModeEnum.ASC
                    ? "mdi-arrow-up"
                    : "mdi-arrow-down"
                }}</v-icon
              >
            </th>
            <th></th>
            <th></th>
          </thead>
          <tbody>
            <tr v-for="(item, i) in allRounds" :key="i">
              <td class="text-center">
                <span
                  v-if="item.id"
                  class="rounds-table-item rounds-table-item-round-number"
                  >{{ item.id }}
                </span>
                <p v-else>-</p>
              </td>
              <td class="text-center">
                <span
                  v-if="item.companyName"
                  class="rounds-table-item rounds-table-item-company-name"
                  >{{ item.companyName }}
                </span>
                <span v-else>-</span>
              </td>
              <td class="text-center">
                <span v-if="item.captureStartDate" class="rounds-table-item"
                  >{{ formatDateHelper(item.captureStartDate) }}
                </span>
                <span v-else>-</span>
              </td>
              <td class="text-center">
                <span v-if="item.captureFinishDate" class="rounds-table-item"
                  >{{ formatDateHelper(item.captureFinishDate) }}
                </span>
                <span v-else>-</span>
              </td>
              <td class="text-center">
                <span v-if="item.roundStatus" class="rounds-table-item"
                  >{{ roundStatusTable(item) }}
                </span>
                <span v-else>-</span>
              </td>
              <td class="text-center">
                <span class="rounds-table-item"
                  >{{ formattedPercentage(item.capturePercentage) }}
                </span>
              </td>
              <td class="text-center">
                <a
                  @click="seeRoundDetails(item.id)"
                  class="rounds-table-link"
                  data-test="Round:Rounds:SeeRoundDetails"
                >
                  <v-icon color="primary">mdi-eye-outline</v-icon>
                  <span>{{ $t("details") }}</span>
                </a>
              </td>
              <td class="text-center" v-show="user.type == userTypeEnum.Admin">
                <a
                  @click="seeRoundAllocations(item.id)"
                  class="rounds-table-link"
                >
                  <div class="rounds-table-link-icon-money">
                    <v-icon color="primary">mdi-currency-usd </v-icon>
                  </div>
                  <span>{{ $t("allocations") }}</span>
                </a>
              </td>
            </tr>
          </tbody>
        </v-simple-table>
        <v-pagination
          color="primary"
          @input="changePage"
          v-model="page"
          :length="pageCount"
        >
        </v-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import ApiService from "@/services/ApiService";

import { roundStatusEnums, roundTableEnum } from "./RoundStatusEnums.js";
import NewDxaButton from "@/components/dxa/Button/NewDxaButton";
import { formatDate } from "@/shared/helpers/dateHelper";
import { findSelectedStatus } from "./helper/findStatusText.js";
import { UserTypeEnum } from "@/shared/enums/UserType";
import { mapGetters, mapMutations } from "vuex";

const OrderFilterEnum = {
  ROUND: roundTableEnum.ROUND,
  COMPANY: roundTableEnum.COMPANY,
  STARTFUNDING: roundTableEnum.STARTFUNDING,
  ENDFUNDING: roundTableEnum.ENDFUNDING,
  STATUS: roundTableEnum.STATUS,
  CAPTURE: roundTableEnum.CAPTURE,
};

const OrderByModeEnum = {
  ASC: 1,
  DESC: 2,
};

export default {
  name: "Rounds",
  components: {
    NewDxaButton,
  },
  data() {
    return {
      user: null,
      formatDateHelper: formatDate,
      apiService: new ApiService(),
      loading: false,
      roundCaptureStartDate: null,
      roundStartMenuDate: false,
      roundCaptureStartDateText: null,
      roundEndMenuDate: false,
      roundsSelectedCompany: null,
      roundsSelectedStatus: null,
      roundsAllCompanies: [],
      roundStatus: roundStatusEnums,
      roundCaptureFinishDateText: null,
      roundCaptureFinishDate: null,
      roundStatusItems: [
        {
          text: "round_status_await_publish",
          value: roundStatusEnums.AWAITING_PUBLISH,
        },
        { text: "round_status_published", value: roundStatusEnums.PUBLISHED },
        { text: "round_status_closed", value: roundStatusEnums.CLOSED },
        { text: "round_status_canceled", value: roundStatusEnums.CANCELED },
      ],
      allRounds: [],
      page: 1,
      pageCount: 1,
      orderBy: OrderFilterEnum.DATE,
      orderByMode: OrderByModeEnum.ASC,
      orderFilterEnum: OrderFilterEnum,
      orderByModeEnum: OrderByModeEnum,
      userTypeEnum: UserTypeEnum,
    };
  },
  async created() {
    let userLocal = JSON.parse(localStorage.getItem("user"));
    this.user = userLocal;

    if (
      userLocal.type === this.userTypeEnum.ManagerUser ||
      (userLocal.managerPartner && userLocal.managerPartner.id)
    ) {
      this.setIsManagerUser(true);
    } else {
      this.setIsManagerUser(false);
    }

    await this.getRoundsByPage();
    await this.getAllCompanies();
  },
  computed: {
    required() {
      return (value) => !!value || this.$t("required");
    },
    ...mapGetters({ isManagerUser: "get_is_manager_user" }),
  },
  methods: {
    ...mapMutations({
      setIsManagerUser: "SET_IS_MANAGER_USER",
    }),
    formatDateOnBlur(date) {
      const [day, month, year] = date.split("/");
      let formateDate;

      if (date.length) {
        formateDate = `${year}-${month}-${day}`;
      } else {
        formateDate = null;
      }

      return formateDate;
    },
    formatInputDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
    saveRoundCaptureStartDate(date) {
      this.roundCaptureStartDateText = this.formatInputDate(
        this.roundCaptureStartDate
      );
      this.$refs.startMenuDateRef.save(date);
    },
    saveRoundCaptureEndDate(date) {
      this.roundCaptureFinishDateText = this.formatInputDate(
        this.roundCaptureFinishDate
      );
      this.$refs.endMenuDateRef.save(date);
    },
    async getAllCompanies() {
      await this.apiService
        .getRequest("company/list")
        .then((result) => {
          this.roundsAllCompanies = JSON.parse(result.message);
        })
        .catch((err) => {
          this.$toast.error(this.$t("error_occoured"));
          return err;
        });
    },
    formattedPercentage(value) {
      let numberToPercentage = value * 100 + "%";

      return numberToPercentage;
    },
    createNewRound() {
      this.$router.push("/round/new");
    },
    async getRoundsByPage() {
      this.loading = true;
      let currentPage = this.page;

      let params = { Page: currentPage };

      if (this.roundsSelectedCompany) {
        params.companyId = this.roundsSelectedCompany;
      }

      if (this.roundsSelectedStatus) {
        params.roundStatus = this.roundsSelectedStatus;
      }

      if (this.roundCaptureStartDate) {
        params.captureStartDate = this.roundCaptureStartDate.toString();
      }

      if (this.roundCaptureFinishDate) {
        params.captureFinishDate = this.roundCaptureFinishDate.toString();
      }

      if (this.orderBy != null) {
        params.orderBy = this.orderBy;
        params.order = this.orderByMode;
      }

      await this.apiService
        .postRequest("investmentround/filter", params)
        .then((result) => {
          const totalItemsPorPage = 20;

          this.page = result.content.currentPage;
          this.pageCount = Math.ceil(result.content.total / totalItemsPorPage);
          this.allRounds = result.content.items;
        })
        .catch((err) => {
          this.$toast.error("error_occoured");
          return err;
        });
      this.loading = false;
    },
    headerClick(col) {
      this.orderBy = col;
      if (this.orderByMode == 1) {
        this.orderByMode = 2;
      } else {
        this.orderByMode = 1;
      }
      this.getRoundsByPage();
    },
    changePage() {
      this.getRoundsByPage();
    },
    translateRoundStatus(item) {
      return this.$t(item.text);
    },
    seeRoundDetails(id) {
      this.$router.push(`/round/details/${id}`);
    },
    seeRoundAllocations(id) {
      this.$router.push(`/round/allocations/${id}`);
    },
    roundStatusTable(roundData) {
      return this.$t(findSelectedStatus(roundData.roundStatus));
    },
  },
};
</script>

<style scoped>
/* general styles */
#rounds-bg {
  position: absolute;
  top: -75px;
  width: 100%;
  min-height: 100vh !important;
  background-color: var(--white);
}

#rounds-content {
  /* 75px precisa ser fixo para das a distancia do AppBar */
  margin-top: calc(75px + 20px);
  width: 100%;
  padding: 0 60px;
  background-color: var(--white);
  padding-bottom: 40px;
}

/* header styles */
.rounds-header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 30px;
}

.rounds-title {
  font-family: "Source Sans Pro";
  font-weight: 700;
  font-size: 35px;
  line-height: 44px;
  letter-spacing: 0.15px;
  color: var(--dark);
  margin-bottom: 8px;
}

.rounds-sub-title {
  font-family: "Source Sans Pro";
  font-weight: 400;
  font-size: 18px;
  line-height: 23px;
  letter-spacing: 0.15px;
  color: #535353;
}

/* styles box filter */
.rounds-filter {
  background-color: var(--white);
  border-radius: 12px;
  padding: 24px;
  min-height: 160px;
}

.rounds-filters-title {
  font-family: "Source Sans Pro";
  font-weight: 600;
  font-size: 18px;
  line-height: 23px;
  letter-spacing: 0.15px;
  color: var(--dark);
  margin-bottom: 24px;
}

.rounds-filters-label {
  font-family: "Source Sans Pro";
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.15px;
  color: var(--dark);
  display: block;
  margin-bottom: 8px;
}

.rounds-filters-auto-complete {
  min-width: 210px;
}

.rounds-filters-date {
  box-sizing: border-box;
  border-radius: 4px;
  min-width: 124px;
}

.rounds-filters-items-flex {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 40px;
  padding-left: 20px;
}

.rounds-date-flex-items {
  display: flex;
  align-items: flex-start;
  gap: 20px;
}

/* para compensar o tamanho do label que ele não tem */
.rounds-filters-item-button-to-filter-box {
  margin-top: 26px;
  max-width: 86px;
}

.rounds-date-item-space-letter {
  display: block;
  font-family: "Source Sans Pro";
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.15px;
  color: #9c9c9c;
  margin-top: 12px;
}

.rounds-filter-expansion-panel-content {
  padding-left: 40px;
}

.rounds-filter {
  margin-bottom: 40px;
}

/* styles table */
.rounds-table-header {
  background: var(--white);
  height: 60px;
}

.rounds-table-head > th {
  font-family: "Source Sans Pro";
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.15px;
  color: var(--dark);
}

.rounds-table-link {
  display: flex;
  align-items: center;
  gap: 8px;
  max-width: max-content;
  text-decoration: none;
}

.rounds-table-link > span {
  font-family: "Source Sans Pro";
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.15px;
  color: var(--primary);
  display: block;
}

.rounds-table-item {
  font-family: "Source Sans Pro";
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.15px;
  color: var(--dark);
}

.rounds-table-item-round-number {
  display: block;
  font-weight: 700;
  color: var(--primary);
}

.rounds-table-item-company-name {
  font-weight: 700;
  color: var(--dark);
}

.box-loading {
  display: grid;
  place-items: center;
}

@media only screen and (max-width: 1140px) {
  .rounds-filters-items-flex {
    grid-template-columns: repeat(2, 1fr);
    gap: 0 40px;
  }

  .rounds-filters-auto-complete {
    min-width: auto;
  }

  .rounds-filters-date {
    min-width: auto;
  }
}

@media only screen and (max-width: 769px) {
  #rounds-bg {
    min-height: 100vh !important;
  }

  .rounds-filter-expansion-panel-content {
    padding-left: 0px;
  }

  .rounds-filters-items-flex {
    grid-template-columns: repeat(1, 1fr);
    gap: 0 40px;
    padding-left: 0px;
  }

  .rounds-filters-item-button-to-filter-box {
    margin-left: 0px;
  }

  .rounds-header {
    flex-direction: column;
    margin-bottom: 30px;
  }

  .rounds-date-flex-items {
    flex-direction: column;
    gap: 0px;
  }

  .rounds-date-item-space-letter {
    margin-top: 0px;
    text-align: center;
    align-self: center;
    margin-bottom: 8px;
  }

  .rounds-sub-title {
    margin-bottom: 20px;
  }
}
</style>
